// Function to fetch the access token
async function fetchAccessToken() {
    try {
        const response = await fetch('https://loving-tight-condor.ngrok-free.app/api/token', {
            method: 'POST', // Ensure the method is POST
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Not authenticated');
        }

        const data = await response.json();
        console.log('Access Token:', data.accessToken);
        return data.accessToken; // Use the access token to make API requests
    } catch (error) {
        console.error('Error fetching access token:', error);
        return null;
    }
}

function loginThenGoToMain(prompt_value) {
    const clientId = '610959923235-ciq4lmqfhes2dv2mg9j9c92n2l6k4p32.apps.googleusercontent.com';
    const redirectUri = 'https://loving-tight-condor.ngrok-free.app/oauth2callback'; // Your server URL
    const scope = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts.readonly';
    const state = encodeURIComponent(JSON.stringify({ redirectUrl: "https://www.rhizom.co/main.html" }));
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=${prompt_value}&state=${state}`;
    window.location.href = authUrl;
}

function runLogin() {
    fetchAccessToken().then(
        access_token => {
            let do_force_relog = localStorage.getItem("force_relog") == "true"
            console.log("DOING RELOG?", do_force_relog)
            if (access_token && !do_force_relog) {
                window.location.replace("./main.html");
            } else {
                let prompt_value;
                if (do_force_relog) {
                    prompt_value = "select_account consent"
                } else {
                    prompt_value = "consent"
                }
                console.log("prompt_value?", prompt_value)
                loginThenGoToMain(prompt_value);
                localStorage.setItem("force_relog", "false")
            }
        }
    )
}

document.getElementById('loginButton').addEventListener('click', runLogin);

document.getElementById('get-started-button').addEventListener('click', runLogin);


// Fetch the access token once the user is authenticated
window.addEventListener('load', fetchAccessToken);

